import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_apiKey,
//   authDomain: process.env.REACT_APP_authDomain,
//   projectId: process.env.REACT_APP_projectId,
//   storageBucket: process.env.REACT_APP_storageBucket,
//   messagingSenderId: process.env.REACT_APP_messagingSenderId,
//   appId: process.env.REACT_APP_appId,
//   measurementId: process.env.REACT_APP_measurementId,
// };
const firebaseConfig = {
  apiKey: "AIzaSyAvm1juT1RVnWsitTr836SvRLMJ88chTQQ",
  authDomain: "cleromemeditation.firebaseapp.com",
  databaseURL: "https://cleromemeditation-default-rtdb.firebaseio.com",
  projectId: "cleromemeditation",
  storageBucket: "cleromemeditation.appspot.com",
  messagingSenderId: "858006895451",
  appId: "1:858006895451:web:267327166c87241f5bf87e",
  measurementId: "G-3P7GMVENW6"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
